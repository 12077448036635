// cities.ts

interface City {
    id: number;
    name: string;
    code: string;
}

export const cities: City[] = [
    { id: 1, name: 'Adana', code: '1' },
    { id: 2, name: 'Adıyaman', code: '2' },
    { id: 3, name: 'Afyonkarahisar', code: '3' },
    { id: 4, name: 'Ağrı', code: '4' },
    { id: 5, name: 'Amasya', code: '5' },
    { id: 6, name: 'Ankara', code: '6' },
    { id: 7, name: 'Antalya', code: '7' },
    { id: 8, name: 'Artvin', code: '8' },
    { id: 9, name: 'Aydın', code: '9' },
    { id: 10, name: 'Balıkesir', code: '10' },
    { id: 11, name: 'Bilecik', code: '11' },
    { id: 12, name: 'Bingöl', code: '12' },
    { id: 13, name: 'Bitlis', code: '13' },
    { id: 14, name: 'Bolu', code: '14' },
    { id: 15, name: 'Burdur', code: '15' },
    { id: 16, name: 'Bursa', code: '16' },
    { id: 17, name: 'Çanakkale', code: '17' },
    { id: 18, name: 'Çankırı', code: '18' },
    { id: 19, name: 'Çorum', code: '19' },
    { id: 20, name: 'Denizli', code: '20' },
    { id: 21, name: 'Diyarbakır', code: '21' },
    { id: 22, name: 'Edirne', code: '22' },
    { id: 23, name: 'Elazığ', code: '23' },
    { id: 24, name: 'Erzincan', code: '24' },
    { id: 25, name: 'Erzurum', code: '25' },
    { id: 26, name: 'Eskişehir', code: '26' },
    { id: 27, name: 'Gaziantep', code: '27' },
    { id: 28, name: 'Giresun', code: '28' },
    { id: 29, name: 'Gümüşhane', code: '29' },
    { id: 30, name: 'Hakkâri', code: '30' },
    { id: 31, name: 'Hatay', code: '31' },
    { id: 32, name: 'Isparta', code: '32' },
    { id: 33, name: 'Mersin', code: '33' },
    { id: 34, name: 'İstanbul', code: '34' },
    { id: 35, name: 'İzmir', code: '35' },
    { id: 36, name: 'Kars', code: '36' },
    { id: 37, name: 'Kastamonu', code: '37' },
    { id: 38, name: 'Kayseri', code: '38' },
    { id: 39, name: 'Kırklareli', code: '39' },
    { id: 40, name: 'Kırşehir', code: '40' },
    { id: 41, name: 'Kocaeli', code: '41' },
    { id: 42, name: 'Konya', code: '42' },
    { id: 43, name: 'Kütahya', code: '43' },
    { id: 44, name: 'Malatya', code: '44' },
    { id: 45, name: 'Manisa', code: '45' },
    { id: 46, name: 'Kahramanmaraş', code: '46' },
    { id: 47, name: 'Mardin', code: '47' },
    { id: 48, name: 'Muğla', code: '48' },
    { id: 49, name: 'Muş', code: '49' },
    { id: 50, name: 'Nevşehir', code: '50' },
    { id: 51, name: 'Niğde', code: '51' },
    { id: 52, name: 'Ordu', code: '52' },
    { id: 53, name: 'Rize', code: '53' },
    { id: 54, name: 'Sakarya', code: '54' },
    { id: 55, name: 'Samsun', code: '55' },
    { id: 56, name: 'Siirt', code: '56' },
    { id: 57, name: 'Sinop', code: '57' },
    { id: 58, name: 'Sivas', code: '58' },
    { id: 59, name: 'Tekirdağ', code: '59' },
    { id: 60, name: 'Tokat', code: '60' },
    { id: 61, name: 'Trabzon', code: '61' },
    { id: 62, name: 'Tunceli', code: '62' },
    { id: 63, name: 'Şanlıurfa', code: '63' },
    { id: 64, name: 'Uşak', code: '64' },
    { id: 65, name: 'Van', code: '65' },
    { id: 66, name: 'Yozgat', code: '66' },
    { id: 67, name: 'Zonguldak', code: '67' },
    { id: 68, name: 'Aksaray', code: '68' },
    { id: 69, name: 'Bayburt', code: '69' },
    { id: 70, name: 'Karaman', code: '70' },
    { id: 71, name: 'Kırıkkale', code: '71' },
    { id: 72, name: 'Batman', code: '72' },
    { id: 73, name: 'Şırnak', code: '73' },
    { id: 74, name: 'Bartın', code: '74' },
    { id: 75, name: 'Ardahan', code: '75' },
    { id: 76, name: 'Iğdır', code: '76' },
    { id: 77, name: 'Yalova', code: '77' },
    { id: 78, name: 'Karabük', code: '78' },
    { id: 79, name: 'Kilis', code: '79' },
    { id: 80, name: 'Osmaniye', code: '80' },
    { id: 81, name: 'Düzce', code: '81' }
];

export const parsedCities = cities.map(city => ({
    value: city.id,
    label: city.name
}));
export function getCityByCode(code: string | undefined): { value: number; label: string } | undefined {
    if (!code) return undefined;
    const city = cities.find(city => city.code === code);
    return city ? { value: city.id, label: city.name } : undefined;
}

